import type {ReactElement} from 'react';
import React from 'react';
import './modal-container.scss';
import {CropperModal} from '@Modals/cropper-modal/cropper-modal';
import {UserMenuModal} from '@Modals/user-menu-modal';
import {TrimAudioModal} from '@Modals/trim-audio-modal';
import {TrimVideoModal} from '@Modals/trim-video-modal';
import {ConfirmationModal} from '@Modals/confirmation-modal';
import {ConfirmDeleteModal} from '@Modals/confirm-delete-modal';
import {MessageModal} from '@Modals/message-modal';
import {SaveConflictModal} from '@Modals/save-conflict-modal';
import {UserTableModal} from '@Modals/user-table-modal';
import {UserScheduleModal} from '@Modals/user-schedule-modal/user-schedule-modal';
import {MoreOptionsModal} from '@Modals/more-options-modal';
import {RoyaltyFiltersModal} from '@Modals/royalty-filters-modal/royalty-filters-modal';
import {ColorBackgroundModal} from '@Modals/color-background-modal';
import {ResizeModal} from '@Modals/resize-modal';
import {ChangePlanModal} from '@Modals/change-plan-modal';
import {EventFiltersModal} from '@Modals/event-filters-modal';
import {EventAreaFiltersModal} from '@Modals/event-area-filters-modal';
import {CreateEventModal} from '@Modals/create-event-modal';
import {EditEventModal} from '@Modals/edit-event-modal';
import {FancytextModal} from '@Modals/fancytext-modal/fancytext-modal';
import {MaskingModal} from '@Modals/masking-modal';
import {PosterItemsListModal} from '@Modals/poster-items-list-modal/poster-items-list-modal';
import {ErrorModal} from '@Modals/error-modal';
import {PosterInGenerationWarningModal} from '@Modals/poster-in-generation-warning-modal';
import {AudioTypeModal} from '@Modals/audio-type-modal';
import {UploadFontAgreementModal} from '@Modals/upload-font-agreement-modal';
import {PMWStockUploadFailedModalModal} from '@Modals/pmw-stock-upload-failed-modal';
import {SocialPostsDialogModal} from '@Modals/social-posts-dialog-modal';
import {UserMediaModal} from '@Modals/user-media-modal';
import {ShareCollectionModal} from '@Modals/share-collection-modal';
import {PosterEditorGridModal} from '@Modals/poster-editor-grid-modal';
import {PosterEditorTitleModal} from '@Modals/poster-editor-title-modal';
import {PosterEditorIntroAnimationModal} from '@Modals/poster-editor-intro-animation-modal';
import {PosterEditorFoldsModal} from '@Modals/poster-editor-folds-modal';
import {PosterEditorItemPositionModal} from '@Modals/poster-editor-item-position-modal/poster-editor-item-position-modal';
import {PosterEditorItemOpacityModal} from '@Modals/poster-editor-item-opacity-modal/poster-editor-item-opacity-modal';
import {PosterEditorItemFontSizeModal} from '@Modals/poster-editor-item-font-size-modal/poster-editor-item-font-size-modal';
import {PosterEditorItemFontStyleModal} from '@Modals/poster-editor-item-font-style-modal/poster-editor-item-font-style-modal';
import {PosterEditorItemSpellcheckModal} from '@Modals/poster-editor-item-spellcheck-modal';
import {PosterEditorItemHyperlinkModal} from '@Modals/poster-editor-item-hyperlink-modal';
import {PosterEditorItemAlignmentModal} from '@Modals/poster-editor-item-alignment-modal';
import {PosterEditorItemShadowModal} from '@Modals/poster-editor-item-shadow-modal';
import {PosterEditorItemFlipModal} from '@Modals/poster-editor-item-flip-modal';
import {PosterEditorItemStretchModal} from '@Modals/poster-editor-item-stretch-modal';
import {PosterEditorItemAdjustmentsModal} from '@Modals/poster-editor-item-adjustments-modal';
import {PosterEditorItemFiltersModal} from '@Modals/poster-editor-item-filters-modal';
import {PosterEditorItemBackgroundModal} from '@Modals/poster-editor-item-background-modal';
import {PosterEditorItemEffectsModal} from '@Modals/poster-editor-item-effects-modal';
import {PosterEditorTextOutlineModal} from '@Modals/poster-editor-text-outline-modal';
import {PosterEditorQrContentModal} from '@Modals/poster-editor-qr-content-modal';
import {PosterEditorQrContentColorModal} from '@Modals/poster-editor-qr-content-color-modal';
import {PosterEditorQrContentBackgroundModal} from '@Modals/poster-editor-qr-content-background-modal';
import {PosterEditorVectorBorderModal} from '@Modals/poster-editor-vector-border-modal';
import {PosterEditorVectorFillModal} from '@Modals/poster-editor-vector-fill-modal';
import {StockModal} from '@Modals/stock-modal';
import {PosterEditorFancyTextModal} from '@Modals/poster-editor-fancy-text-modal';
import {PosterEditorFancyTextEffectModal} from '@Modals/poster-editor-fancy-text-effect-modal';
import {PosterEditorFancyTextFontsModal} from '@Modals/poster-editor-fancy-text-fonts-modal';
import {PosterEditorDrawBrushModal} from '@Modals/poster-editor-draw-brush-modal';
import {PosterEditorDrawBrushSizeModal} from '@Modals/poster-editor-draw-brush-size-modal';
import {PosterEditorDrawBrushColorModal} from '@Modals/poster-editor-draw-brush-color-modal';
import {PosterEditorDrawingColorModal} from '@Modals/poster-editor-drawing-color-modal';
import {PosterEditorNumberOfTabsModal} from '@Modals/poster-editor-number-of-tabs-modal';
import {PosterEditorTabsTextModal} from '@Modals/poster-editor-tabs-text-modal';
import {PosterEditorItemLayoutModal} from '@Modals/poster-editor-item-layout-modal';
import {PosterEditorItemDateStylesModal} from '@Modals/poster-editor-item-date-styles-modal';
import {PosterEditorItemStyle2Modal} from '@Modals/poster-editor-item-style-2-modal';
import {PosterEditorLayoutItemBorderModal} from '@Modals/poster-editor-layout-item-border-modal';
import {PosterEditorLayoutItemSpacingModal} from '@Modals/poster-editor-layout-item-spacing-modal';
import {PosterEditorMenuItemIconStylesModal} from '@Modals/poster-editor-menu-item-icon-styles-modal';
import {PosterEditorSlideshowApplyPropertiesModal} from '@Modals/poster-editor-slideshow-apply-properties-modal';
import {PosterEditorSlideshowSlidesModal} from '@Modals/poster-editor-slideshow-slides-modal';
import {PosterEditorTextFillModal} from '@Modals/poster-editor-text-fill-modal';
import {PosterEditorLayoutFillModal} from '@Modals/poster-editor-layout-fill-modal';
import {PosterEditorNavMoreOptionsModal} from '@Modals/poster-editor-nav-more-options-modal';
import {PosterEditorLanguageModal} from '@Modals/poster-editor-language-modal';
import {SocialMediaWizardMobileFooterModal} from '@Modals/social-media-wizard-mobile-footer-modal';
import {SocialAccountsConnectionModal} from '@Modals/social-accounts-connection-modal';
import {MoveMultimediaModal} from '@Modals/move-multimedia-modal';
import {PosterAddItemsModal} from '@Modals/poster-add-items-modal/poster-add-items-modal';
import {PosterEditorTextItemFontModal} from '@Modals/poster-editor-text-item-font-modal';
import {PosterEditorMoreOptionsModal} from '@Modals/poster-editor-more-options-modal/poster-editor-more-options-modal';
import {PosterEditorBleedModal} from '@Modals/poster-editor-bleed-modal';
import {PosterEditorLayoutItemFontModal} from '@Modals/poster-editor-layout-item-font-modal';
import {PosterEditorSlideshowTransitionModal} from '@Modals/poster-editor-slideshow-transition-modal';
import {PosterEditorFancytextShapeModal} from '@Modals/poster-editor-fancytext-shape-modal';
import {PosterEditorTabItemSeparatorModal} from '@Modals/poster-editor-tab-item-separator-modal';
import {PosterEditorFancyTextColorsModal} from '@Modals/poster-editor-fancy-text-colors-modal';
import {PosterEditorItemListModal} from '@Modals/poster-editor-item-list-modal';
import {DurationDropdownModal} from '@Modals/duration-dropdown-modal/duration-dropdown-modal';
import {EditAudioItemModal} from '@Modals/edit-audio-item-modal/edit-audio-item-modal';
import {MystuffMobileNavigationOptionsModal} from '@Modals/mystuff-mobile-navigation-options-modal';
import {ReplaceMediaModal} from '@Modals/replace-media-modal';
import {SocialMediaResizeModal} from '@Components/modals/social-media-resize-modal';
import {PosterEditorExportModal} from '@Modals/poster-editor-export-modal';
import {CollectionItemMoreOptionsModal} from '@Modals/collection-item-more-options-modal';
import {EmailMetricsDownloadPDFModal} from '@Modals/email-metrics-download-pdf-modal';
import {AudioItemRenameModal} from '@Modals/audio-item-rename-modal';
import {AutoCaptionsModal} from '@Modals/auto-captions-modal';
import {PosterEditorBackgroundTypeModal} from '@Modals/poster-editor-background-type-modal';
import {ReplaceBackgroundImageModal} from '@Modals/replace-background-image-modal';
import {PosterEditorAddSlideModal} from '@Modals/poster-editor-add-slide-modal';
import {AddFancytextColorModal} from '@Modals/add-fancytext-color-modal';
import {EmbedVideosEmailmakerInputModal} from '@Modals/embed-videos-emailmaker-input-modal';
import {PosterEditorEditTextModal} from '@Modals/poster-editor-edit-text-modal';
import {EmbeddedEditorConsentModal} from '@Modals/embedded-editor-consent-modal';
import {MobileMystuffFolderOptionsModal} from '@Modals/mobile-mystuff-folder-options-modal';
import {PosterEditorGuidesModal} from '@Modals/poster-editor-guides-modal';
import {PosterEditorItemLetterSpacingModal} from '@Modals/poster-editor-item-letter-spacing-modal';
import {PosterEditorItemLineHeightModal} from '@Modals/poster-editor-item-line-height-modal';
import {PosterEditorReplaceAudioModal} from '@Modals/poster-editor-replace-audio-modal';
import {SocialPostSelectDesignModal} from '@Modals//social-post-select-design-modal';
import {ConnectSSOAccountModal} from '@Modals/connect-sso-account-modal';
import {RecordImageAndVideoModal} from '@Modals/record-image-and-video-modal';
import {RecordAudioModal} from '@Modals/record-audio-modal';
import {CompleteSignupModal} from '@Modals/complete-signup-modal/complete-signup-modal';
import {ConfirmRemoveVideoBackgroundModal} from '@Modals/confirm-remove-video-background-modal';
import {SettingsModal} from '@Modals/settings-modal';
import {PremiumAgreementModal} from '@Modals/premium-agreement-modal';
import {AuthenticateModal} from '@Modals/authenticate-modal';
import {MediaTypeModal} from '@Modals/media-type-modal/media-type-modal';
import {AudienceEditInfoModal} from '@Components/email-marketing-campaign-audience/components/audience-edit-info-modal';
import {TwoFactorAuthenticationVerificationModal} from '@Modals/two-factor-authentication-verification-modal';
import {PasswordVerificationModal} from '@Modals/password-verification-modal';
import {ManageTwoFactorAuthenticationModal} from '@Modals/manage-two-factor-authentication-modal';
import {AccountDeletionModal} from '@Modals/account-deletion-modal';
import {MODALS_IDS} from '@Components/modal-container/modal-container.types';
import {BrandActivityLogModal} from '@Modals/brand-activity-log-modal';
import {BrandColorModal} from '@Modals/brand-color-modal';
import {EditPosterSubtitlesModal} from '@Modals/edit-poster-subtitles-modal/edit-poster-subtitles-modal';
import {EditPosterSubtitleMoreOptionsModal} from '@Modals/edit-poster-subtitle-more-options-modal/edit-poster-subtitle-more-options-modal';
import {ResumableStockModal} from '@Modals/resumable-stock-modal';
import {TimelineModal} from '@Modals/timeline-modal/timeline-modal';
import {PublishingOptionsModal} from '@Modals/publishing-options-modal/publishing-options-modal';
import {AiTextPromptBoxModal} from '@Modals/ai-text-prompt-box-modal/ai-text-prompt-box-modal';
import {SocialMediaSelectCoverModal} from '@Modals/social-media-select-cover-modal';
import {PosterSubtitleTemplateStylesModal} from '@Modals/poster-subtitle-template-styles-modal/poster-subtitle-template-styles-modal';
import {GoogleMapSelectorModal} from '@Modals/google-map-selector-modal';
import {EmailCampaignDialogModal} from '@Modals/email-campaign-dialog-modal/email-campaign-dialog-modal';
import {PosterEditorRectangleRoundingModal} from '@Modals/poster-editor-rectangle-rounding-modal/poster-editor-rectangle-rounding-modal';
import {useAppSelector} from '@/hooks';

export function ModalContainer(): ReactElement {
  const modalsData = useAppSelector((state) => {
    return state.modals.modalsHashmap;
  });

  const getModalFromModalId = (modalId: string): ReactElement => {
    switch (modalId) {
      case MODALS_IDS.CROPPER_MODAL:
        return <CropperModal key={modalId} />;
      case MODALS_IDS.USER_MENU_MODAL:
        return <UserMenuModal key={modalId} />;
      case MODALS_IDS.TRIM_AUDIO_MODAL:
        return <TrimAudioModal key={modalId} />;
      case MODALS_IDS.TRIM_VIDEO_MODAL:
        return <TrimVideoModal key={modalId} />;
      case MODALS_IDS.CONFIRMATION_MODAL:
        return <ConfirmationModal key={modalId} />;
      case MODALS_IDS.COMPLETE_SIGNUP_MODAL:
        return <CompleteSignupModal />;
      case MODALS_IDS.CONFIRM_REMOVE_VIDEO_BACKGROUND_MODAL:
        return <ConfirmRemoveVideoBackgroundModal key={modalId} />;
      case MODALS_IDS.POSTER_ITEMS_LIST_MODAL:
        return <PosterItemsListModal key={modalId} />;
      case MODALS_IDS.ERROR_MODAL:
        return <ErrorModal key={modalId} />;
      case MODALS_IDS.CONFIRM_DELETE_MODAL:
        return <ConfirmDeleteModal key={modalId} />;
      case MODALS_IDS.MESSAGE_MODAL:
        return <MessageModal key={modalId} />;
      case MODALS_IDS.SAVE_CONFLICT_MODAL:
        return <SaveConflictModal key={modalId} />;
      case MODALS_IDS.AUDIO_TYPE_MODAL:
        return <AudioTypeModal key={modalId} />;
      case MODALS_IDS.USER_TABLE_MODAL:
        return <UserTableModal key={modalId} />;
      case MODALS_IDS.USER_SCHEDULE_MODAL:
        return <UserScheduleModal key={modalId} />;
      case MODALS_IDS.MORE_OPTIONS_MODAL:
        return <MoreOptionsModal key={modalId} />;
      case MODALS_IDS.COLLECTION_ITEM_MORE_OPTIONS_MODAL:
        return <CollectionItemMoreOptionsModal key={modalId} />;
      case MODALS_IDS.AUDIO_ITEM_RENAME_MODAL:
        return <AudioItemRenameModal key={modalId} />;
      case MODALS_IDS.ROYALTY_FILTERS_MODAL:
        return <RoyaltyFiltersModal key={modalId} />;
      case MODALS_IDS.EVENT_FILTERS_MODAL:
        return <EventFiltersModal key={modalId} />;
      case MODALS_IDS.EVENT_AREA_FILTERS_MODAL:
        return <EventAreaFiltersModal key={modalId} />;
      case MODALS_IDS.CREATE_EVENT_MODAL:
        return <CreateEventModal key={modalId} />;
      case MODALS_IDS.EDIT_EVENT_MODAL:
        return <EditEventModal key={modalId} />;
      case MODALS_IDS.COLOR_BACKGROUND_MODAL:
        return <ColorBackgroundModal key={modalId} />;
      case MODALS_IDS.POSTER_IN_GENERATION_MODAL:
        return <PosterInGenerationWarningModal key={modalId} />;
      case MODALS_IDS.RESIZE_MODAL:
        return <ResizeModal key={modalId} />;
      case MODALS_IDS.MASKING_MODAL:
        return <MaskingModal key={modalId} />;
      case MODALS_IDS.CHANGE_PLAN_MODAL:
        return <ChangePlanModal key={modalId} />;
      case MODALS_IDS.FANCYTEXT_MODAL:
        return <FancytextModal key={modalId} />;
      case MODALS_IDS.UPLOAD_FONT_AGREEMENT_MODAL:
        return <UploadFontAgreementModal key={modalId} />;
      case MODALS_IDS.PMW_STOCK_UPLOAD_FAILED_MODAL:
        return <PMWStockUploadFailedModalModal key={modalId} />;
      case MODALS_IDS.USER_MEDIA_MODAL:
        return <UserMediaModal key={modalId} />;
      case MODALS_IDS.SHARE_COLLECTION_MODAL:
        return <ShareCollectionModal key={modalId} />;
      case MODALS_IDS.SOCIAL_POSTS_DIALOG_MODAL:
        return <SocialPostsDialogModal key={modalId} />;
      case MODALS_IDS.STOCK_MODAL:
        return <StockModal key={modalId} />;
      case MODALS_IDS.RESUMABLE_STOCK_MODAL:
        return <ResumableStockModal key={modalId} />;
      case MODALS_IDS.SOCIAL_MEDIA_WIZARD_MOBILE_FOOTER_MODAL:
        return <SocialMediaWizardMobileFooterModal key={modalId} />;
      case MODALS_IDS.SOCIAL_ACCOUNTS_CONNECTION_MODAL:
        return <SocialAccountsConnectionModal key={modalId} />;
      case MODALS_IDS.MOVE_MULTIMEDIA_MODAL:
        return <MoveMultimediaModal key={modalId} />;
      case MODALS_IDS.POSTER_EDITOR_ADD_ITEM_MODAL:
        return <PosterAddItemsModal key={modalId} />;
      case MODALS_IDS.SOCIAL_MEDIA_RESIZE_OPTIONS:
        return <SocialMediaResizeModal key={modalId} />;
      case MODALS_IDS.MY_STUFF_MOBILE_NAVIGATION_MODAL:
        return <MystuffMobileNavigationOptionsModal key={modalId} />;
      case MODALS_IDS.REPLACE_MEDIA_MODAL:
        return <ReplaceMediaModal key={modalId} />;
      case MODALS_IDS.REPLACE_BACKGROUND_IMAGE_MODAL:
        return <ReplaceBackgroundImageModal key={modalId} />;
      case MODALS_IDS.POSTER_EDITOR_GRID_MODAL:
        return <PosterEditorGridModal key={modalId} />;
      case MODALS_IDS.POSTER_EDITOR_TITLE_MODAL:
        return <PosterEditorTitleModal key={modalId} />;
      case MODALS_IDS.POSTER_EDITOR_INTRO_ANIMATION_MODAL:
        return <PosterEditorIntroAnimationModal key={modalId} />;
      case MODALS_IDS.POSTER_EDITOR_FOLDS_MODAL:
        return <PosterEditorFoldsModal key={modalId} />;
      case MODALS_IDS.POSTER_EDITOR_ITEM_POSITION_MODAL:
        return <PosterEditorItemPositionModal key={modalId} />;
      case MODALS_IDS.POSTER_EDITOR_ITEM_OPACITY_MODAL:
        return <PosterEditorItemOpacityModal key={modalId} />;
      case MODALS_IDS.POSTER_EDITOR_ITEM_FONT_SIZE_MODAL:
        return <PosterEditorItemFontSizeModal key={modalId} />;
      case MODALS_IDS.POSTER_EDITOR_ITEM_FONT_STYLE_MODAL:
        return <PosterEditorItemFontStyleModal key={modalId} />;
      case MODALS_IDS.POSTER_EDITOR_ITEM_FONT_STYLE_2_MODAL:
        return <PosterEditorItemStyle2Modal key={modalId} />;
      case MODALS_IDS.POSTER_EDITOR_ITEM_SPELLCHECK_MODAL:
        return <PosterEditorItemSpellcheckModal key={modalId} />;
      case MODALS_IDS.POSTER_EDITOR_ITEM_HYPERLINK_MODAL:
        return <PosterEditorItemHyperlinkModal key={modalId} />;
      case MODALS_IDS.POSTER_EDITOR_ITEM_ALIGNMENT_MODAL:
        return <PosterEditorItemAlignmentModal key={modalId} />;
      case MODALS_IDS.POSTER_EDITOR_ITEM_SHADOW_MODAL:
        return <PosterEditorItemShadowModal key={modalId} />;
      case MODALS_IDS.POSTER_EDITOR_ITEM_FLIP_MODAL:
        return <PosterEditorItemFlipModal key={modalId} />;
      case MODALS_IDS.POSTER_EDITOR_ITEM_STRETCH_MODAL:
        return <PosterEditorItemStretchModal key={modalId} />;
      case MODALS_IDS.POSTER_EDITOR_ITEM_ADJUSTMENTS_MODAL:
        return <PosterEditorItemAdjustmentsModal key={modalId} />;
      case MODALS_IDS.POSTER_EDITOR_TEXT_FILL_MODAL:
        return <PosterEditorTextFillModal key={modalId} />;
      case MODALS_IDS.POSTER_EDITOR_LAYOUT_FILL_MODAL:
        return <PosterEditorLayoutFillModal key={modalId} />;
      case MODALS_IDS.POSTER_EDITOR_ITEM_FILTERS_MODAL:
        return <PosterEditorItemFiltersModal key={modalId} />;
      case MODALS_IDS.POSTER_EDITOR_ITEM_BACKGROUND_MODAL:
        return <PosterEditorItemBackgroundModal key={modalId} />;
      case MODALS_IDS.POSTER_EDITOR_ITEM_EFFECTS_MODAL:
        return <PosterEditorItemEffectsModal key={modalId} />;
      case MODALS_IDS.POSTER_EDITOR_TEXT_OUTLINE_MODAL:
        return <PosterEditorTextOutlineModal key={modalId} />;
      case MODALS_IDS.POSTER_EDITOR_QR_CONTENT_MODAL:
        return <PosterEditorQrContentModal key={modalId} />;
      case MODALS_IDS.POSTER_EDITOR_QR_CONTENT_COLOR_MODAL:
        return <PosterEditorQrContentColorModal key={modalId} />;
      case MODALS_IDS.POSTER_EDITOR_QR_CONTENT_BACKGROUND_MODAL:
        return <PosterEditorQrContentBackgroundModal key={modalId} />;
      case MODALS_IDS.POSTER_EDITOR_VECTOR_BORDER_MODAL:
        return <PosterEditorVectorBorderModal key={modalId} />;
      case MODALS_IDS.POSTER_EDITOR_VECTOR_FILL_MODAL:
        return <PosterEditorVectorFillModal key={modalId} />;
      case MODALS_IDS.POSTER_EDITOR_FANCY_TEXT_MODAL:
        return <PosterEditorFancyTextModal key={modalId} />;
      case MODALS_IDS.POSTER_EDITOR_FANCY_TEXT_EFFECT_MODAL:
        return <PosterEditorFancyTextEffectModal key={modalId} />;
      case MODALS_IDS.POSTER_EDITOR_FANCY_TEXT_FONT_MODAL:
        return <PosterEditorFancyTextFontsModal key={modalId} />;
      case MODALS_IDS.POSTER_EDITOR_DRAW_BRUSH_MODAL:
        return <PosterEditorDrawBrushModal key={modalId} />;
      case MODALS_IDS.POSTER_EDITOR_DRAW_BRUSH_SIZE_MODAL:
        return <PosterEditorDrawBrushSizeModal key={modalId} />;
      case MODALS_IDS.POSTER_EDITOR_DRAW_BRUSH_COLOR_MODAL:
        return <PosterEditorDrawBrushColorModal key={modalId} />;
      case MODALS_IDS.POSTER_EDITOR_DRAWING_COLOR_MODAL:
        return <PosterEditorDrawingColorModal key={modalId} />;
      case MODALS_IDS.POSTER_EDITOR_MORE_OPTIONS_MODAL:
        return <PosterEditorMoreOptionsModal key={modalId} />;
      case MODALS_IDS.POSTER_EDITOR_NUMBER_OF_TABS_MODAL:
        return <PosterEditorNumberOfTabsModal key={modalId} />;
      case MODALS_IDS.POSTER_EDITOR_TABS_TEXT_MODAL:
        return <PosterEditorTabsTextModal key={modalId} />;
      case MODALS_IDS.POSTER_EDITOR_ITEM_LAYOUT_MODAL:
        return <PosterEditorItemLayoutModal key={modalId} />;
      case MODALS_IDS.POSTER_EDITOR_ITEM_DATE_STYLES_MODAL:
        return <PosterEditorItemDateStylesModal key={modalId} />;
      case MODALS_IDS.POSTER_EDITOR_LAYOUT_ITEM_BORDER_MODAL:
        return <PosterEditorLayoutItemBorderModal key={modalId} />;
      case MODALS_IDS.POSTER_EDITOR_LAYOUT_ITEM_SPACING_MODAL:
        return <PosterEditorLayoutItemSpacingModal key={modalId} />;
      case MODALS_IDS.POSTER_EDITOR_MENU_ITEM_ICON_STYLES_MODAL:
        return <PosterEditorMenuItemIconStylesModal key={modalId} />;
      case MODALS_IDS.POSTER_EDITOR_SLIDESHOW_APPLY_PROPERTIES_MODAL:
        return <PosterEditorSlideshowApplyPropertiesModal key={modalId} />;
      case MODALS_IDS.POSTER_EDITOR_SLIDESHOW_SLIDES_MODAL:
        return <PosterEditorSlideshowSlidesModal key={modalId} />;
      case MODALS_IDS.POSTER_EDITOR_NAV_MORE_OPTIONS_MODAL:
        return <PosterEditorNavMoreOptionsModal key={modalId} />;
      case MODALS_IDS.POSTER_EDITOR_LANGUAGE_MODAL:
        return <PosterEditorLanguageModal key={modalId} />;
      case MODALS_IDS.POSTER_EDITOR_TEXT_ITEM_FONT_MODAL:
        return <PosterEditorTextItemFontModal key={modalId} />;
      case MODALS_IDS.POSTER_EDITOR_BLEED_MODAL:
        return <PosterEditorBleedModal key={modalId} />;
      case MODALS_IDS.POSTER_EDITOR_LAYOUT_ITEM_FONT_MODAL:
        return <PosterEditorLayoutItemFontModal key={modalId} />;
      case MODALS_IDS.POSTER_EDITOR_SLIDESHOW_TRANSITION_MODAL:
        return <PosterEditorSlideshowTransitionModal key={modalId} />;
      case MODALS_IDS.POSTER_EDITOR_FANCY_TEXT_SHAPE_MODAL:
        return <PosterEditorFancytextShapeModal key={modalId} />;
      case MODALS_IDS.POSTER_EDITOR_TAB_ITEM_SEPARATOR_MODAL:
        return <PosterEditorTabItemSeparatorModal key={modalId} />;
      case MODALS_IDS.POSTER_EDITOR_FANCY_TEXT_COLORS_MODAL:
        return <PosterEditorFancyTextColorsModal key={modalId} />;
      case MODALS_IDS.POSTER_EDITOR_ITEM_LIST_MODAL:
        return <PosterEditorItemListModal key={modalId} />;
      case MODALS_IDS.POSTER_EDITOR_BACKGROUND_TYPE_MODAL:
        return <PosterEditorBackgroundTypeModal key={modalId} />;
      case MODALS_IDS.DURATION_DROPDOWN_MODAL:
        return <DurationDropdownModal key={modalId} />;
      case MODALS_IDS.EDIT_AUDIO_ITEM_MODAL:
        return <EditAudioItemModal key={modalId} />;
      case MODALS_IDS.POSTER_EDITOR_EXPORT_MODAL:
        return <PosterEditorExportModal key={modalId} />;
      case MODALS_IDS.POSTER_EDITOR_ADD_SLIDESHOW_MODAL:
        return <PosterEditorAddSlideModal key={modalId} />;
      case MODALS_IDS.EMAIL_METRICS_DOWNLOAD_PDF_MODAL:
        return <EmailMetricsDownloadPDFModal key={modalId} />;
      case MODALS_IDS.EMBED_VIDEOS_EMAILMAKER_MODAL:
        return <EmbedVideosEmailmakerInputModal key={modalId} />;
      case MODALS_IDS.ADD_FANCYTEXT_COLOR_MODAL:
        return <AddFancytextColorModal key={modalId} />;
      case MODALS_IDS.POSTER_EDITOR_EDIT_TEXT_MODAL:
        return <PosterEditorEditTextModal key={modalId} />;
      case MODALS_IDS.MY_STUFF_MOBILE_FOLDER_OPTIONS:
        return <MobileMystuffFolderOptionsModal key={modalId} />;
      case MODALS_IDS.AUTO_CAPTIONS_MODAL:
        return <AutoCaptionsModal key={modalId} />;
      case MODALS_IDS.EMBEDDED_EDITOR_CONSENT_MODAL:
        return <EmbeddedEditorConsentModal key={modalId} />;
      case MODALS_IDS.POSTER_EDITOR_GUIDES_MODAL:
        return <PosterEditorGuidesModal key={modalId} />;
      case MODALS_IDS.POSTER_EDITOR_ITEM_LETTER_SPACING_MODAL:
        return <PosterEditorItemLetterSpacingModal key={modalId} />;
      case MODALS_IDS.POSTER_EDITOR_ITEM_LINE_HEIGHT_MODAL:
        return <PosterEditorItemLineHeightModal key={modalId} />;
      case MODALS_IDS.POSTER_EDITOR_REPLACE_AUDIO_MODAL:
        return <PosterEditorReplaceAudioModal key={modalId} />;
      case MODALS_IDS.RECORD_IMAGE_AND_VIDEO_MODAL:
        return <RecordImageAndVideoModal key={modalId} />;
      case MODALS_IDS.RECORD_AUDIO_MODAL:
        return <RecordAudioModal key={modalId} />;
      case MODALS_IDS.CONNECT_SSO_ACCOUNT_MODAL:
        return <ConnectSSOAccountModal key={modalId} />;
      case MODALS_IDS.SOCIAL_POST_SELECT_DESIGN_MODAL:
        return <SocialPostSelectDesignModal key={modalId} />;
      case MODALS_IDS.SOCIAL_MEDIA_SELECT_COVER_MODAL:
        return <SocialMediaSelectCoverModal key={modalId} />;
      case MODALS_IDS.MEDIA_TYPE_MODAL:
        return <MediaTypeModal key={modalId} />;
      case MODALS_IDS.AUDIENCE_EDIT_INFO_MODAL:
        return <AudienceEditInfoModal key={modalId} />;
      case MODALS_IDS.SETTINGS_MODAL:
        return <SettingsModal key={modalId} />;
      case MODALS_IDS.PREMIUM_AGREEMENT_MODAL:
        return <PremiumAgreementModal key={modalId} />;
      case MODALS_IDS.AUTHENTICATE_MODAL:
        return <AuthenticateModal key={modalId} />;
      case MODALS_IDS.TWO_FACTOR_AUTHENTICATION_VERIFICATION_MODAL:
        return <TwoFactorAuthenticationVerificationModal key={modalId} />;
      case MODALS_IDS.PASSWORD_VERIFICATION_MODAL:
        return <PasswordVerificationModal key={modalId} />;
      case MODALS_IDS.MANAGE_TWO_FACTOR_AUTHENTICATION_MODAL:
        return <ManageTwoFactorAuthenticationModal key={modalId} />;
      case MODALS_IDS.BRAND_ACTIVITY_MODAL:
        return <BrandActivityLogModal key={modalId} />;
      case MODALS_IDS.BRAND_COLOR_MODAL:
        return <BrandColorModal key={modalId} />;
      case MODALS_IDS.PUBLISHING_OPTIONS_MODAL:
        return <PublishingOptionsModal key={modalId} />;
      case MODALS_IDS.EDIT_POSTER_SUBTITLES_MODAL:
        return <EditPosterSubtitlesModal key={modalId} />;
      case MODALS_IDS.EDIT_POSTER_SUBTITLE_MORE_OPTIONS_MODAL:
        return <EditPosterSubtitleMoreOptionsModal key={modalId} />;
      case MODALS_IDS.AI_TEXT_PROMPT_BOX_MODAL:
        return <AiTextPromptBoxModal key={modalId} />;
      case MODALS_IDS.TIMELINE_MODAL:
        return <TimelineModal key={modalId} />;
      case MODALS_IDS.ACCOUNT_DELETION_MODAL:
        return <AccountDeletionModal key={modalId} />;
      case MODALS_IDS.POSTER_SUBTITLE_TEMPLATE_STYLES_MODAL:
        return <PosterSubtitleTemplateStylesModal key={modalId} />;
      case MODALS_IDS.GOOGLE_MAP_SELECTOR_MODAL:
        return <GoogleMapSelectorModal key={modalId} />;
      case MODALS_IDS.EMAIL_CAMPAIGN_DIALOG_MODAL:
        return <EmailCampaignDialogModal key={modalId} />;
      case MODALS_IDS.POSTER_EDITOR_RECTANGLE_ROUNDING_MODAL:
        return <PosterEditorRectangleRoundingModal key={modalId} />;
      default:
        throw new Error('Invalid modal id provided. No modal exists for this id');
    }
  };

  const getModals = (): Array<ReactElement> => {
    const modals = [];
    for (const [modalId] of Object.entries(modalsData)) {
      modals.push(getModalFromModalId(modalId));
    }
    return modals;
  };

  return <>{getModals()}</>;
}

export const isModalOpen = (): boolean => {
  for (const [, modalData] of Object.entries(window.PMW.redux.store.getState().modals.modalsHashmap)) {
    if (modalData.isOpen) {
      return true;
    }
  }
  return false;
};
